import React from "react"
import styled from "styled-components"
import * as Buttons from "../components/Buttons"
import * as Containers from "../components/Containers"
import { individual } from "../components/Data/featureList"
import Layout from "../components/layout"
import ListToggleItem from "../components/Lists/ListToggleItem"
import SEO from "../components/seo"
import FeaturesCTAMobile from "../images/artwork/Features-CTA_Mobile_v2.svg"
import FeaturesHeroMobile from "../images/artwork/Features-Hero-Mobile_v1.svg"
import FeaturesIndivCTAGraphics from "../images/artwork/Features-Indiv-CTA-Graphics_v3.svg"
import FeaturesIndivHeroGraphicsBrowser from "../images/artwork/Features-Indiv-Hero-Graphics-Browser_v2.svg"
import FeaturesIndivHeroGraphicsDesign from "../images/artwork/Features-Indiv-Hero-Graphics-Design_v1.svg"
import FeaturesIndivHeroGraphicsTech from "../images/artwork/Features-Indiv-Hero-Graphics-Tech_v1.svg"
import FeaturesIndivHeroGraphicsUX from "../images/artwork/Features-Indiv-Hero-Graphics-UX_v3.svg"

const FeaturesPage = () => (
  <Layout>
    <SEO title="Features" />
    <Containers.PageContainer>
      <Containers.SectionContainer
        style={{
          flexDirection: "column",
        }}
      >
        {/* <HeroBg> */}
        <HeroContainer>
          <HeroBg>
            <HeroContent>
              <h1>Built by one of you, for all of you</h1>
              <p>
                Comprehensive features and workflows to support you throughout
                your professional journey
              </p>
            </HeroContent>
          </HeroBg>
          <HeroBgMobile src={FeaturesHeroMobile} alt="Features" />
          <HeroBgDesign
            width="120"
            height="150"
            src={FeaturesIndivHeroGraphicsDesign}
            alt="UI"
          />
          <HeroBgUX
            width="200"
            height="150"
            src={FeaturesIndivHeroGraphicsUX}
            alt="UX"
          />
          <HeroBgTech
            width="120"
            height="150"
            src={FeaturesIndivHeroGraphicsTech}
            alt="Technology"
          />
        </HeroContainer>

        {/* </HeroBg> */}
        {/* <HeroBg
          src={FeaturesIndivHeroGraphics}
          alt="Image"
        /> */}
      </Containers.SectionContainer>

      {individual.map((item, index) => {
        if (index % 2 === 0) {
          return (
            <Containers.SectionContainer key={index}>
              <ListToggleItem section={index} item={item} type="individual" />
            </Containers.SectionContainer>
          )
        }
        return (
          <Containers.SectionContainer key={index}>
            <ListToggleItem
              section={index}
              item={item}
              type="individual"
              left
            />
          </Containers.SectionContainer>
        )
      })}

      <CTAContainer>
        <CTABg src={FeaturesIndivCTAGraphics} alt="Reliable" />
        <CTABgMobile src={FeaturesCTAMobile} alt="Reliable" />
        <CTAContent>
          <h2>
            With Uuoni, become the professional your clients always recall first
          </h2>
          <CTAButton to="/contact">Request Demo Now</CTAButton>
        </CTAContent>
      </CTAContainer>
    </Containers.PageContainer>
  </Layout>
)

export default FeaturesPage

const CTAContainer = styled(Containers.CTAContainer)`
  @media screen and (max-width: 767px) {
    height: 100%;
    width: 100%;
  }
`

const HeroContainer = styled.div`
  position: relative;
  height: min(800px, 44vw);
  margin-bottom: 3rem;

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: auto;
  }
  @media screen and (max-width: 767px) {
    margin-top: 5%;
  }
`

const HeroBg = styled.div`
  position: relative;
  background-image: url(${FeaturesIndivHeroGraphicsBrowser});
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: 50% 65%;
  margin-top: 0%;
  display: flex;

  @media screen and (max-width: 1024px) {
    background-image: none;
  }

  @media screen and (min-width: 1025px) {
    height: 100%;
  }
`

const HeroBgMobile = styled.img`
  transform: scale(1.11);
  margin-bottom: 0;

  @media screen and (min-width: 1025px) {
    display: none;
  }
`

const HeroBgDesign = styled.img`
  position: absolute;
  top: 56%;
  left: 12%;
  width: 10%;
  height: 10%;
  transform: scale(8.5);

  @media screen and (max-width: 1024px) {
    display: none;
  }
`

const HeroBgUX = styled.img`
  position: absolute;
  bottom: 8%;
  left: 45%;
  width: 10%;
  height: 10%;
  transform: scale(5.5);

  @media screen and (max-width: 1024px) {
    display: none;
  }
`

const HeroBgTech = styled.img`
  position: absolute;
  top: 45%;
  right: 13%;
  width: 10%;
  height: 10%;
  transform: scale(9);

  @media screen and (max-width: 1024px) {
    display: none;
  }
`

const HeroContent = styled.div`
  margin: auto;
  text-align: center;
  width: 40%;
  position: relative;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  @media screen and (min-width: 1025px) {
    margin-top: 16%;
  }
  @media screen and (max-width: 767px) {
    text-align: left;
  }
`

const CTABgOld = styled.div`
  position: relative;
  background-image: url(${FeaturesIndivCTAGraphics});
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: -1%;

  @media screen and (max-width: 767px) {
    display: none;
  }
`

const CTABg = styled.img`
  position: relative;
  width: 45%;
  margin-left: -1%;

  @media screen and (max-width: 767px) {
    display: none;
  }
`

const CTABgMobile = styled.img`
  position: relative;
  width: 100%;
  height: 100%;

  @media screen and (min-width: 768px) {
    display: none;
  }
`

const CTAContent = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    position: relative;
    align-self: flex-end;
    margin-top: auto;
    margin-bottom: auto;
    width: 55%;
    margin-right: 5%;
    margin-left: 5%;
    text-align: left;

    h2 {
      margin-bottom: 1.875rem;
    }
  }

  @media screen and (max-width: 767px) {
    width: 80%;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const CTAButton = styled(Buttons.ButtonLink)`
  align-self: flex-start;

  @media screen and (max-width: 767px) {
    align-self: center;
  }
`
