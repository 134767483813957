import React, { lazy, Suspense } from "react"
import styled from "styled-components"
import FeaturesIndivBodyS1BGElement from "../../images/artwork/Features-Indiv-BodyS1-BGElement_v2.svg"
import FeaturesIndivBodyS2_5_BGElement from "../../images/artwork/Features-Indiv-BodyS2-5-BGElement_v2.svg"
import FeaturesIndivBodyS2BGElement from "../../images/artwork/Features-Indiv-BodyS2-BGElement_v2.svg"
import FeaturesIndivBodyS3BGElement from "../../images/artwork/Features-Indiv-BodyS3-BGElement_v2.svg"
import FeaturesIndivBodyS4BGElement from "../../images/artwork/Features-Indiv-BodyS4-BGElement_v2.svg"
import FeaturesTeamsBodyS1_5_BGElement from "../../images/artwork/Features-Teams-BodyS1-5-BGElement_v2.svg"
import FeaturesTeamsBodyS2_5_BGElement from "../../images/artwork/Features-Teams-BodyS2-5-BGElement_v2.svg"
import FeaturesTeamsBodyS2BGElement from "../../images/artwork/Features-Teams-BodyS2-BGElement_v1.svg"
import FeaturesTeamsBodyS3BGElement from "../../images/artwork/Features-Teams-BodyS3-BGElement_v1.svg"
import Screenshot from "../Screenshots"

const ListToggleItem = props => {
  const [item, setItem] = React.useState(props.item)
  const [open, setOpen] = React.useState(0)
  const [key, setKey] = React.useState(null)
  const [header, setHeader] = React.useState(null)

  React.useEffect(() => {
    let initKey = item.content[0].key
    setKey(initKey)
  }, [])

  React.useEffect(() => {
    if (header) {
      scroll(header)
    }
  }, [header])

  const getOffsetTop = element => {
    let offsetTop = 0
    while (element) {
      offsetTop += element.offsetTop
      element = element.offsetParent
    }
    return offsetTop
  }

  const scroll = header => {
    let mq = window.matchMedia("(max-width: 767px)")
    let mqTab = window.matchMedia("(max-width: 1024px)")
    if (mqTab.matches) {
      var ele = document.getElementById(header)
      let offset = getOffsetTop(ele) - 50

      if (mq.matches) {
        let val = offset - 50
        offset = val
      }
      window.scrollTo({
        top: offset,
        left: 0,
        behavior: "smooth",
      })
    }
  }

  const handleExpand = (index, key, header) => {
    setKey(key)
    setOpen(index)
    setHeader(header)
    scroll(header)
  }

  const getBG = (key, type, position) => {
    if (type === "individual")
      switch (key) {
        case 0:
          return (
            <BackgroundContainer
              left={position}
              background={FeaturesIndivBodyS1BGElement}
            />
          )
        case 1:
          return (
            <BackgroundContainer
              left={position}
              background={FeaturesIndivBodyS2BGElement}
              style={{ backgroundSize: "95%", right: "-8.5%" }}
            />
          )

        case 2:
          return (
            <>
              <BackgroundContainer
                left={position}
                background={FeaturesIndivBodyS3BGElement}
              />
              <BackgroundContainer
                left={position}
                background={FeaturesIndivBodyS2_5_BGElement}
                style={{ backgroundSize: "40%", left: "40%", top: "80%" }}
              />
            </>
          )

        case 3:
          return (
            <BackgroundContainer
              left={position}
              background={FeaturesIndivBodyS4BGElement}
              style={{ backgroundSize: "95%", right: "-8.5%" }}
            />
          )

        default:
          return
      }
    else {
      switch (key) {
        case 0:
          return (
            <BackgroundContainer
              left={position}
              background={FeaturesTeamsBodyS1_5_BGElement}
              style={{
                backgroundSize: "75%",
                right: "-19%",
                left: "unset",
                top: "77%",
              }}
            />
          )
        case 1:
          return (
            <BackgroundContainer
              left={position}
              background={FeaturesTeamsBodyS2BGElement}
              style={{ backgroundSize: "31%", right: "0%", top: "70%" }}
            />
          )
        case 2:
          return (
            <>
              <BackgroundContainer
                left={position}
                background={FeaturesTeamsBodyS2_5_BGElement}
                style={{ backgroundSize: "50%", top: "-17%" }}
              />
              <BackgroundContainer
                left={position}
                background={FeaturesTeamsBodyS3BGElement}
                style={{ backgroundSize: "23%", left: "37%", top: "80%" }}
              />
            </>
          )
        default:
          return
      }
    }
  }

  return (
    <ContentWrapper>
      {getBG(props.section, props.type, props.left)}
      <ImageContainer left={props.left}>
        {key && <Screenshot item={key} type={props.type} />}
      </ImageContainer>
      <ContentContainer left={props.left}>
        <h2>{item.heading}</h2>
        <h5 style={{ marginBottom: "2.5rem" }}>
          <span>{item.subHeading}</span>
        </h5>
        <ToggleListContainer>
          {item.content.map(({ key, header, content, icon }, index) => {
            const Icon = lazy(() => import(`../../images/${icon}.inline.svg`))
            const isSSR = typeof window === "undefined"
            return (
              <ToggleContainer
                id={header}
                key={index}
                expanded={index === open}
                onClick={() => handleExpand(index, key, header)}
              >
                <ToggleContentContainer>
                  {!isSSR && (
                    <Suspense fallback={<div>Loading...</div>}>
                      <Icon style={{ width: "5rem", height: "5rem" }} />
                    </Suspense>
                  )}
                  <div style={{ marginLeft: "2rem", width: "80%" }}>
                    {index === open ? (
                      <ContentHeader open>{header}</ContentHeader>
                    ) : (
                      <ContentHeader>{header}</ContentHeader>
                    )}
                    {index === open && (
                      <>
                        <p className="content">{content}</p>
                      </>
                    )}
                  </div>
                </ToggleContentContainer>
                {index === open && (
                  <MobileImageContainer>
                    {key && <Screenshot item={key} type={props.type} />}
                  </MobileImageContainer>
                )}
              </ToggleContainer>
            )
          })}
        </ToggleListContainer>
      </ContentContainer>
    </ContentWrapper>
  )
}

export default ListToggleItem

const ContentHeader = styled.h6`
  margin-bottom: ${props => props.open && "1rem"};
  font-weight: ${props => (props.open ? 600 : 500)};
`

// height: 850px;
const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }

  @media screen and (min-width: 1025px) {
    height: clamp(850px, 50vw, 850px);
  }
`

const ToggleListContainer = styled.ul`
  margin: 0px;

  @media screen and (min-width: 1025px) {
    width: 92%;
  }
`

const ToggleContainer = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  box-shadow: ${props =>
    props.expanded ? "0px 0px 0px 2px #5e80e6" : "0px 4px 3px -1.5px #c4c4c4"};
  transition: all 0.25s;
  margin-bottom: 3rem;
  overflow: hidden;

  scroll-snap-align: start;

  :hover {
    div > p :not(.content) {
      font-weight: 600;
    }
  }

  :hover,
  :focus {
    box-shadow: 0px 0px 0px 2px #5e80e6;
  }

  @media screen and (min-width: 1025px) {
    width: 100%;
  }
`

const ToggleContentContainer = styled.div`
  display: flex;
  width: 100%;
  text-align: left;
  align-items: center;
  padding: 3% 3%;

  @media screen and (max-width: 767px) {
    margin-bottom: 5%;
  }
`

// const Image = styled.img`
//   position: absolute;
//   top: 0;
//   left: 0;
//   margin: 0;
//   visibility: ${props => (props.visible ? "visible" : "hidden")};
//   opacity: ${props => (props.visible ? "1" : "0")};
//   transition: visibility linear 0.33s, opacity 0.33s linear;
//   width: 500px;
//   height: 500px;
// `

const ImageContainer = styled.div`
  order: ${props => (props.left ? 1 : 0)};
  width: 50%;
  position: relative;
  height: 100%;
  display: block;

  @media screen and (max-width: 1024px) {
    order: 1;
    width: 100%;
    display: none;
  }
`
const MobileImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: block;

  @media screen and (min-width: 1025px) {
    display: none;
  }
`

const BackgroundContainer = styled.div`
  background-image: ${props => props.background && `url(${props.background})`};
  background-repeat: no-repeat;
  background-size: 100%;
  width: 50%;
  height: 100%;
  position: absolute;
  z-index: -100;

  right: ${props => props.left && "-6%"};
  left: ${props => !props.left && "-6%"};

  @media screen and (max-width: 1024px) {
    display: none;
  }
`

const ContentContainer = styled.div`
  order: ${props => (props.left ? 0 : 1)};
  height: 100%;
  width: 100%;

  @media screen and (min-width: 1025px) {
    width: 50%;
    margin-left: ${props => !props.left && "3%"};
    margin-right: ${props => props.left && "3%"};
  }
  @media screen and (max-width: 1024px) {
    order: 0;
  }
`
