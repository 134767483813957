import React from "react"
import styled, { css } from "styled-components"
import FeaturesIndivPt1 from "../../images/screenshots/Features-Indiv-Pt1_v6.svg"
import FeaturesIndivPt2 from "../../images/screenshots/Features-Indiv-Pt2_v4.svg"
import FeaturesIndivPt3 from "../../images/screenshots/Features-Indiv-Pt3_v3.svg"
import FeaturesIndivPt4 from "../../images/screenshots/Features-Indiv-Pt4_v4.svg"
import FeaturesIndivPt5 from "../../images/screenshots/Features-Indiv-Pt5_v3.svg"
import FeaturesIndivPt6 from "../../images/screenshots/Features-Indiv-Pt6_v5.svg"
import FeaturesIndivPt7 from "../../images/screenshots/Features-Indiv-Pt7_v4.svg"
import FeaturesIndivPt8 from "../../images/screenshots/Features-Indiv-Pt8_v5.svg"
import FeaturesIndivPt9 from "../../images/screenshots/Features-Indiv-Pt9_v4.svg"
import FeaturesIndivPt10 from "../../images/screenshots/Features-Indiv-Pt10_v5.svg"
import FeaturesIndivPt11 from "../../images/screenshots/Features-Indiv-Pt11_v4.svg"

import FeaturesIndivMobilePt1 from "../../images/screenshots/Features-Indiv-Mobile-Pt1_v1.svg"
import FeaturesIndivMobilePt2 from "../../images/screenshots/Features-Indiv-Mobile-Pt2_v1.svg"
import FeaturesIndivMobilePt3 from "../../images/screenshots/Features-Indiv-Mobile-Pt3_v2.svg"
import FeaturesIndivMobilePt4 from "../../images/screenshots/Features-Indiv-Mobile-Pt4_v2.svg"
import FeaturesIndivMobilePt5 from "../../images/screenshots/Features-Indiv-Mobile-Pt5_v1.svg"
import FeaturesIndivMobilePt6 from "../../images/screenshots/Features-Indiv-Mobile-Pt6_v1.svg"
import FeaturesIndivMobilePt7 from "../../images/screenshots/Features-Indiv-Mobile-Pt7_v1.svg"
import FeaturesIndivMobilePt8 from "../../images/screenshots/Features-Indiv-Mobile-Pt8_v1.svg"
import FeaturesIndivMobilePt9 from "../../images/screenshots/Features-Indiv-Mobile-Pt9_v1.svg"
import FeaturesIndivMobilePt10 from "../../images/screenshots/Features-Indiv-Mobile-Pt10_v1.svg"
import FeaturesIndivMobilePt11 from "../../images/screenshots/Features-Indiv-Mobile-Pt11_v1.svg"

import FeaturesIndivTabletPt1 from "../../images/screenshots/Features-Indiv-Tablet-Pt1_v1.svg"
import FeaturesIndivTabletPt2 from "../../images/screenshots/Features-Indiv-Tablet-Pt2_v1.svg"
import FeaturesIndivTabletPt3 from "../../images/screenshots/Features-Indiv-Tablet-Pt3_v2.svg"
import FeaturesIndivTabletPt4 from "../../images/screenshots/Features-Indiv-Tablet-Pt4_v1.svg"
import FeaturesIndivTabletPt5 from "../../images/screenshots/Features-Indiv-Tablet-Pt5_v1.svg"
import FeaturesIndivTabletPt6 from "../../images/screenshots/Features-Indiv-Tablet-Pt6_v1.svg"
import FeaturesIndivTabletPt7 from "../../images/screenshots/Features-Indiv-Tablet-Pt7_v1.svg"
import FeaturesIndivTabletPt8 from "../../images/screenshots/Features-Indiv-Tablet-Pt8_v1.svg"
import FeaturesIndivTabletPt9 from "../../images/screenshots/Features-Indiv-Tablet-Pt9_v1.svg"
import FeaturesIndivTabletPt10 from "../../images/screenshots/Features-Indiv-Tablet-Pt10_v1.svg"
import FeaturesIndivTabletPt11 from "../../images/screenshots/Features-Indiv-Tablet-Pt11_v1.svg"

import FeaturesTeamsPt1 from "../../images/screenshots/Features-Teams-Pt1_v2.svg"
import FeaturesTeamsPt2 from "../../images/screenshots/Features-Teams-Pt2_v2.svg"
import FeaturesTeamsPt3 from "../../images/screenshots/Features-Teams-Pt3_v2.svg"
import FeaturesTeamsPt4 from "../../images/screenshots/Features-Teams-Pt4_v2.svg"
import FeaturesTeamsPt5 from "../../images/screenshots/Features-Teams-Pt5_v2.svg"
import FeaturesTeamsPt6 from "../../images/screenshots/Features-Teams-Pt6_v2.svg"

import FeaturesTeamsMobilePt1 from "../../images/screenshots/Features-Teams-Mobile-Pt1_v3.svg"
import FeaturesTeamsMobilePt2 from "../../images/screenshots/Features-Teams-Mobile-Pt2_v2.svg"
import FeaturesTeamsMobilePt3 from "../../images/screenshots/Features-Teams-Mobile-Pt3_v1.svg"
import FeaturesTeamsMobilePt4 from "../../images/screenshots/Features-Teams-Mobile-Pt4_v1.svg"
import FeaturesTeamsMobilePt5 from "../../images/screenshots/Features-Teams-Mobile-Pt5_v2.svg"
import FeaturesTeamsMobilePt6 from "../../images/screenshots/Features-Teams-Mobile-Pt6_v1.svg"

import FeaturesTeamsTabletPt1 from "../../images/screenshots/Features-Teams-Tablet-Pt1_v2.svg"
import FeaturesTeamsTabletPt2 from "../../images/screenshots/Features-Teams-Tablet-Pt2_v2.svg"
import FeaturesTeamsTabletPt3 from "../../images/screenshots/Features-Teams-Tablet-Pt3_v1.svg"
import FeaturesTeamsTabletPt4 from "../../images/screenshots/Features-Teams-Tablet-Pt4_v1.svg"
import FeaturesTeamsTabletPt5 from "../../images/screenshots/Features-Teams-Tablet-Pt5_v2.svg"
import FeaturesTeamsTabletPt6 from "../../images/screenshots/Features-Teams-Tablet-Pt6_v1.svg"

const getScreenshot = (key, type) => {
  if (type === "individual")
    switch (key) {
      case 1:
        return <FeaturesIndivPt1Image />
      case 2:
        return <FeaturesIndivPt2Image />
      case 3:
        return <FeaturesIndivPt3Image />
      case 4:
        return <FeaturesIndivPt4Image />
      case 5:
        return <FeaturesIndivPt5Image />
      case 6:
        return <FeaturesIndivPt6Image />
      case 7:
        return <FeaturesIndivPt7Image />
      case 8:
        return <FeaturesIndivPt8Image />
      case 9:
        return <FeaturesIndivPt9Image />
      case 10:
        return <FeaturesIndivPt10Image />
      case 11:
        return <FeaturesIndivPt11Image />
      default:
        return
    }
  else {
    switch (key) {
      case 1:
        return <FeaturesTeamsPt1Image />
      case 2:
        return <FeaturesTeamsPt2Image />
      case 3:
        return <FeaturesTeamsPt3Image />
      case 4:
        return <FeaturesTeamsPt4Image />
      case 5:
        return <FeaturesTeamsPt5Image />
      case 6:
        return <FeaturesTeamsPt6Image />
      default:
        return
    }
  }
}

const Screenshot = props => {
  return <>{getScreenshot(props.item, props.type)}</>
}

export default Screenshot

const ImageStyle = css`
  display: block;
  margin: 0;
  animation: fade 1s;
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  width: 100%;
  height: 0%;

  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media screen and (min-width: 1025px) {
    height: 100%;
    background-position: center;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    padding-bottom: 60%;
    width: 101%;
  }

  @media screen and (max-width: 767px) {
    padding-bottom: 140%;
  }
`

const FeaturesTeamsPt1Image = styled.div`
  ${ImageStyle}

  @media screen and (min-width: 1025px) {
    background-image: url(${FeaturesTeamsPt1});
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    background-image: url(${FeaturesTeamsTabletPt1});
  }

  @media screen and (max-width: 767px) {
    background-image: url(${FeaturesTeamsMobilePt1});
  }
`

const FeaturesTeamsPt2Image = styled.div`
  ${ImageStyle}

  @media screen and (min-width: 1025px) {
    background-image: url(${FeaturesTeamsPt2});
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    background-image: url(${FeaturesTeamsTabletPt2});
  }

  @media screen and (max-width: 767px) {
    padding-bottom: 115%;
    background-image: url(${FeaturesTeamsMobilePt2});
  }
`

const FeaturesTeamsPt3Image = styled.div`
  ${ImageStyle}

  @media screen and (min-width: 1025px) {
    background-image: url(${FeaturesTeamsPt3});
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    background-image: url(${FeaturesTeamsTabletPt3});
  }

  @media screen and (max-width: 767px) {
    background-image: url(${FeaturesTeamsMobilePt3});
  }
`

const FeaturesTeamsPt4Image = styled.div`
  ${ImageStyle}

  @media screen and (min-width: 1025px) {
    background-image: url(${FeaturesTeamsPt4});
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    background-image: url(${FeaturesTeamsTabletPt4});
  }

  @media screen and (max-width: 767px) {
    background-image: url(${FeaturesTeamsMobilePt4});
  }
`

const FeaturesTeamsPt5Image = styled.div`
  ${ImageStyle}

  @media screen and (min-width: 1025px) {
    background-image: url(${FeaturesTeamsPt5});
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    background-image: url(${FeaturesTeamsTabletPt5});
  }

  @media screen and (max-width: 767px) {
    padding-bottom: 115%;
    background-image: url(${FeaturesTeamsMobilePt5});
  }
`

const FeaturesTeamsPt6Image = styled.div`
  ${ImageStyle}

  @media screen and (min-width: 1025px) {
    background-image: url(${FeaturesTeamsPt6});
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    background-image: url(${FeaturesTeamsTabletPt6});
  }

  @media screen and (max-width: 767px) {
    padding-bottom: 125%;
    background-image: url(${FeaturesTeamsMobilePt6});
  }
`

const FeaturesIndivPt1Image = styled.div`
  ${ImageStyle}

  @media screen and (min-width: 1025px) {
    background-image: url(${FeaturesIndivPt1});
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    background-image: url(${FeaturesIndivTabletPt1});
  }

  @media screen and (max-width: 767px) {
    background-image: url(${FeaturesIndivMobilePt1});
  }
`

const FeaturesIndivPt2Image = styled.div`
  ${ImageStyle}

  @media screen and (min-width: 1025px) {
    background-image: url(${FeaturesIndivPt2});
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    background-image: url(${FeaturesIndivTabletPt2});
  }

  @media screen and (max-width: 767px) {
    padding-bottom: 110%;
    background-image: url(${FeaturesIndivMobilePt2});
  }
`
const FeaturesIndivPt3Image = styled.div`
  ${ImageStyle}

  @media screen and (min-width: 1025px) {
    background-image: url(${FeaturesIndivPt3});
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    background-image: url(${FeaturesIndivTabletPt3});
  }

  @media screen and (max-width: 767px) {
    padding-bottom: 95%;
    background-image: url(${FeaturesIndivMobilePt3});
  }
`
const FeaturesIndivPt4Image = styled.div`
  ${ImageStyle}

  @media screen and (min-width: 1025px) {
    background-image: url(${FeaturesIndivPt4});
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    background-image: url(${FeaturesIndivTabletPt4});
  }

  @media screen and (max-width: 767px) {
    background-image: url(${FeaturesIndivMobilePt4});
  }
`
const FeaturesIndivPt5Image = styled.div`
  ${ImageStyle}

  @media screen and (min-width: 1025px) {
    background-image: url(${FeaturesIndivPt5});
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    background-image: url(${FeaturesIndivTabletPt5});
  }

  @media screen and (max-width: 767px) {
    background-image: url(${FeaturesIndivMobilePt5});
  }
`
const FeaturesIndivPt6Image = styled.div`
  ${ImageStyle}

  @media screen and (min-width: 1025px) {
    background-image: url(${FeaturesIndivPt6});
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    background-image: url(${FeaturesIndivTabletPt6});
  }

  @media screen and (max-width: 767px) {
    background-image: url(${FeaturesIndivMobilePt6});
  }
`
const FeaturesIndivPt7Image = styled.div`
  ${ImageStyle}

  @media screen and (min-width: 1025px) {
    background-image: url(${FeaturesIndivPt7});
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    background-image: url(${FeaturesIndivTabletPt7});
  }

  @media screen and (max-width: 767px) {
    padding-bottom: 125%;
    background-image: url(${FeaturesIndivMobilePt7});
  }
`
const FeaturesIndivPt8Image = styled.div`
  ${ImageStyle}

  @media screen and (min-width: 1025px) {
    background-image: url(${FeaturesIndivPt8});
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    background-image: url(${FeaturesIndivTabletPt8});
  }

  @media screen and (max-width: 767px) {
    padding-bottom: 130%;
    background-image: url(${FeaturesIndivMobilePt8});
  }
`
const FeaturesIndivPt9Image = styled.div`
  ${ImageStyle}

  @media screen and (min-width: 1025px) {
    background-image: url(${FeaturesIndivPt9});
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    background-image: url(${FeaturesIndivTabletPt9});
  }

  @media screen and (max-width: 767px) {
    background-image: url(${FeaturesIndivMobilePt9});
  }
`
const FeaturesIndivPt10Image = styled.div`
  ${ImageStyle}

  @media screen and (min-width: 1025px) {
    background-image: url(${FeaturesIndivPt10});
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    background-image: url(${FeaturesIndivTabletPt10});
  }

  @media screen and (max-width: 767px) {
    background-image: url(${FeaturesIndivMobilePt10});
  }
`
const FeaturesIndivPt11Image = styled.div`
  ${ImageStyle}

  @media screen and (min-width: 1025px) {
    background-image: url(${FeaturesIndivPt11});
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    background-image: url(${FeaturesIndivTabletPt11});
  }

  @media screen and (max-width: 767px) {
    padding-bottom: 110%;
    background-image: url(${FeaturesIndivMobilePt11});
  }
`
