const individual = [
  {
    heading: "Quicker, Structured Processes",
    subHeading:
      "Standardising the core processes gives you more time to focus on what’s important.",
    content: [
      {
        key: 1,
        header: "Standardised Transaction Process",
        content:
          "Faster, transparent process of tracking activities and documents for transactions between insurance companies and more importantly, your clients.",
        icon: "Proposal-Template_v1",
      },
      {
        key: 2,
        header: "Better management of processes",
        content:
          "Work and communication processes are tracked and streamlined using Uuoni’s document management system, reducing back-and-forth.",
        icon: "Processes_v1",
      },
      {
        key: 3,
        header: "Automated Notifications",
        content:
          "Be kept informed of every step: multiple proposals, quotations, recommendations etc, so you can take action where necessary.",
        icon: "Notification_v2",
      },
    ],
  },
  {
    heading: "Centralised Dashboard",
    subHeading:
      "The digital ‘Bird’s-Eye-View’ approach to monitoring your business.",
    content: [
      {
        key: 4,
        header: "Keep track of the essentials",
        content:
          "Keep track of your sales, handle document uploads securely, and manage your communications all in one place.",
        icon: "Tracking_v1",
      },
      {
        key: 5,
        header: "Efficiently manage your cases",
        content:
          "Prioritise and organise your cases with our Transaction Management, and even personalise them further with Remarks.",
        icon: "Organizing_v1",
      },
      {
        key: 6,
        header: "Personalized Scheduler",
        content:
          "Automatically organise all of your clients’ insurance renewals, claims, birthdays, and more with our Scheduler.",
        icon: "Scheduler_v1",
      },
    ],
  },
  {
    heading: "Paperless Office",
    subHeading: "Readily available documents accessible anywhere, at all times",
    content: [
      {
        key: 7,
        header: "Lesser paperwork, lesser mess",
        content:
          "Replace the cumbersome, messy paperwork with our digital documents handling. Upload, send, retrieve, all on the go!",
        icon: "Paperwork_v1",
      },
      {
        key: 8,
        header: "Customisable E-invoice",
        content:
          "Generate and manage e-invoices with customisable fields to tailor to your clients’ needs.",
        icon: "E-invoice_v2",
      },
    ],
  },
  {
    heading: "Build and cultivate lasting relationships",
    subHeading:
      "Can’t remember if Mr Ong’s wife’s favourite brand is Celine or Chanel? Worry not, we’re here to help!",
    content: [
      {
        key: 9,
        header: "Relationship Tagging",
        content:
          "Tag your client’s likes and dislikes and watch your client be impressed with your attention to detail.",
        icon: "Relationship-Tagging_v1",
      },
      {
        key: 10,
        header: "Anticipate your clients’ needs",
        content:
          "Easily access your client’s information across the platform, and better value-add to their needs.",
        icon: "Client-Needs_v1",
      },
      {
        key: 11,
        header: "Smart Alerts",
        content:
          "Be notified on-the-go with our smart alerts! Don’t miss the opportunity to ask Mr Ong out again when his birthday is near.",
        icon: "Smart-Alerts_v1",
      },
    ],
  },
]

const team = [
  {
    heading: "Lead your team with conviction",
    subHeading: "Coordinate and motivate your team on a deeper level",
    content: [
      {
        key: 1,
        header: "Onboard and manage your team",
        content:
          "Onboard your team with the help of guided tours and always be in-tuned with their progress.",
        icon: "Performance_v1",
      },
      {
        key: 2,
        header: "Manage and assign roles",
        content:
          "Our task delegation and role-assigning tools makes it easier for you to build on your team’s strengths.",
        icon: "Task-Delegation_v2",
      },
    ],
  },
  {
    heading: "Organise and collaborate like clockwork",
    subHeading:
      "Come together as one to improve your overall business efficiency",
    content: [
      {
        key: 3,
        header: "Improve the team’s efficiency",
        content:
          "Manage your team’s schedules, smart-track performance targets, and other ad hoc activities all on one platform.",
        icon: "Team-Efficiency_v1",
      },
      {
        key: 4,
        header: "Drive the team’s success with collaborations",
        content:
          "Partnering up with your teammates just got easier. Be it working on a case together or training your new members, collaboration events help your team share and track information easily.",
        icon: "Reduce-Comms-Confusion_v1",
      },
    ],
  },
  {
    heading: "Go beyond your targets with Data Analytics",
    subHeading:
      "Data used the right way brings your business to a whole new level",
    content: [
      {
        key: 5,
        header: "Easy-to-understand reports",
        content:
          "Make sense of your team’s performance data with easy-to-understand reports for more effective delegation and performance management.",
        icon: "Report_v1",
      },
      {
        key: 6,
        header: "Powered by data",
        content:
          "By harnessing the knowledge gained with data, your top salespeople and marketers will be able to gather and close deals more efficiently, and effectively.",
        icon: "Data-Analytics_v1",
      },
    ],
  },
]

export { individual, team }
